// https://www.gatsbyjs.org/docs/adding-tags-and-categories-to-blog-posts/#add-tags-to-your-markdown-files

import React from 'react';
import { Link, graphql } from 'gatsby';

import { kebabCase } from 'lodash';

import Layout from '../components/layout';
const TagsPage = ({ data }) => {
  const bizcard = data.bizcards.edges[0].node
  const allTags = data.allMarkdownRemark.group;

  return (
    <Layout bizcard={bizcard}>
      <div className="blog-tags">
        <h1>Tags</h1>
        <ul className="tags">
          {allTags.map(tag => (
            <li key={tag.fieldValue}>
              <Link to={`/tags/${kebabCase(tag.fieldValue)}/`} className="tag">
                {tag.fieldValue} ({tag.totalCount})
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </Layout>
  );
};

export default TagsPage;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(limit: 2000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }

    bizcards: allMarkdownRemark(filter: {frontmatter: {templateKey: {eq: "bizcard-page"}}}) {
      edges {
        node {
          id
          fields {
            slug
          }
          html
          frontmatter {
            title
            date
            description
            featuredpost
            templateKey
            name
            company
            phoneOffice
            phoneMobile
            email
            website
            address
            facebook
            twitter
            linkedin
            instagram
            youtube
            links {
              linkName
              linkUrl
              icon {
                childImageSharp {
                  fluid(maxWidth: 300) {
                    aspectRatio
                    base64
                    sizes
                    src
                    srcSet
                  }
                }
              }
            }
            img {
              childImageSharp {
                fluid(maxWidth: 900) {
                  aspectRatio
                  base64
                  sizes
                  src
                  srcSet
                }
              }
            }
            logo {
              childImageSharp {
                fluid(maxWidth: 800) {
                  aspectRatio
                  base64
                  sizes
                  src
                  srcSet
                }
              }
            }
          }
        }
      }
    }
  }
`;
